



































import { Component, Prop, Vue } from 'vue-property-decorator'

interface InlineSelectOption {
  label: string
  value: string
}

@Component({
  inheritAttrs: false,
})
export default class InlineSelect extends Vue {
  @Prop() id?: string
  @Prop() value?: string
  @Prop() ariaLabelledby?: string
  @Prop() options!: InlineSelectOption[]

  get listeners() {
    return {
      ...this.$listeners,
      input: (e: any) => this.$emit('input', e.target.value),
    }
  }

  get currentIndex() {
    return this.options.findIndex(o => this.value === o.value)
  }

  selectOptionByIndex(newIndex: number) {
    const safeIndex = (this.options.length + newIndex) % this.options.length
    const newValue = this.options[safeIndex].value
    const newOptionEl = (this.$refs.options as HTMLElement[])[safeIndex]
    newOptionEl.focus()
    this.$emit('input', newValue)
  }

  onClickOption(index: number) {
    this.selectOptionByIndex(index)
  }

  onKeyupLeft(e: any) {
    this.selectOptionByIndex(this.currentIndex - 1)
  }

  onKeyupRight(e: any) {
    this.selectOptionByIndex(this.currentIndex + 1)
  }
}
