













































































import { Component, Ref, Watch, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Drawer from '@/components/Drawer.vue'
import IconOcarina from '@/components/IconOcarina.vue'
import IconClose from '@/components/IconClose.vue'
import IconSymbol from '@/components/IconSymbol.vue'
import InlineSelect from '@/components/InlineSelect.vue'

@Component({
  components: {
    Drawer,
    IconOcarina,
    IconClose,
    IconSymbol,
    InlineSelect,
  },
  computed: mapState(['ui']),
})
export default class App extends Vue {
  @Ref() readonly drawer!: Drawer

  ui!: any

  get strings() {
    return {
      dialogAriaLabel: `Ocarina Settings`,
      appTitle: `Ocarina`,
      reloadHint: `Screws fall out all the time. Reloading Ocarina should get the sound going again.`,
      reloadButtonLabel: `Reload`,
      updateHint: `This Ocarina is a little out-of-date, please take a moment to update it.`,
      updateButtonLabel: `Update`,
      layoutFormLabel: `Layout`,
      hintTextTitle: 'How to Play',
      hintText: [
        'Unmute your device and tap the buttons to play the notes, little touchscreens are best.',
        'Audio is ready when the dot is green. Red means something broke.',
        'On a QWERTY keyboard, keys S to L play C5 to C6.',
        'The N64 layout is wholly nostalgic. The A button is D5, C-Up is D6.',
      ],
    }
  }

  // Lifecycle

  queryDarkMode = window.matchMedia('(prefers-color-scheme: dark)')
  isStandalone = window.matchMedia('(display-mode: standalone)').matches
  isIos = /iPhone|iPad|iPod/.test(navigator.userAgent)

  created() {
    if (this.isStandalone) {
      document.documentElement.classList.add('is-app')
    }
    if (this.isIos) {
      document.documentElement.classList.add('is-ios')
    }
    document.addEventListener('workerupdated', this.onWorkerUpdated)

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.ui.reloading) return
        this.ui.reloading = true
        location.reload()
      })
    }
  }

  mounted() {
    this.onDarkModeChange(this.queryDarkMode)
    this.queryDarkMode.addListener(this.onDarkModeChange)
  }

  onWorkerUpdated(e: any) {
    this.$store.commit('ui', {
      key: 'worker',
      value: e.detail,
    })
    this.$store.commit('ui', {
      key: 'updateAvailable',
      value: true,
    })
  }

  onDarkModeChange(query: any) {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]')
    if (metaThemeColor) {
      if (query.matches) {
        metaThemeColor.setAttribute('content', '#000')
      } else {
        metaThemeColor.setAttribute('content', '#fff')
      }
    }
  }

  // Drawer

  get drawerExpanded() {
    return this.ui.drawerExpanded
  }

  set drawerExpanded(value) {
    this.$store.dispatch('expandDrawer', value)
  }

  afterDrawerEnter() {
    this.drawer.focusFirstinContent()
  }

  onClickReload() {
    location.reload()
  }

  onClickUpdate() {
    if (!this.ui.worker || !this.ui.worker) return
    this.ui.worker.waiting.postMessage({ type: 'SKIP_WAITING' })
  }

  get synthLayout() {
    return this.ui.synthLayout
  }

  set synthLayout(value) {
    this.$store.commit('ui', {
      key: 'synthLayout',
      value,
    })
  }
}
