



































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class IconClose extends Vue {
  @Prop({ default: 'Close' }) ariaLabel!: string
}
