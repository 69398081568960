











import { Component, Prop, Vue } from 'vue-property-decorator'
import { Frequency } from 'tone'

@Component
export default class SynthKey extends Vue {
  @Prop() note!: string

  get frequency() {
    return Frequency(this.note).toFrequency()
  }

  get pitchClass() {
    return Frequency(this.note).toMidi() % 12
  }

  get style() {
    return {
      '--pitch-class': this.pitchClass,
    }
  }
}
